.background {
    background-color: #FFF;
    display: flex;
    width: 1440px;
    height: 1000px;
    justify-content: center;
    align-self: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 900px) {
        width: 360px;
        height: auto;
    }
}

.container {
    display: flex;
    width: 944px;
    margin-top: 200px;
    padding: 48px 48px 48px 48px;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    border-radius: 40px;
    background: #F6F8FC;

    @media (max-width: 900px) {
        margin-top: 80px;
        width: 328px;
        display: flex;
        padding: 24px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;
        border-radius: 30px;
    }

    span {
        color: var(--Dark, #182F43);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    h2 {
        margin: 0px;
        padding-bottom: 10px;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 100.5%;
        letter-spacing: -1.92px;
        background: var(--_, linear-gradient(180deg, #2B5D89 0%, #182F43 87.61%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 900px) {
            font-size: 48px;
            letter-spacing: -1.44px;
        }
    }
}

.top_button {
    padding: 10px;
    border-radius: 40px;
    background: #E4F1FD;
    color: var(--Blue, #2D7CD8);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 0;
}

.form_contacts {
    display: flex;
    flex-direction: row;
    gap: 16px;

    @media (max-width: 900px) {
        flex-direction: column;
    }

    input {
        border-radius: 20px;
        border: 1px solid #EBEEF3;
        background: #FFF;

    }
}

.form_message {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 22px;

    textarea {
        width: 885px;
        height: 110px;
        padding: 16px;
        border-radius: 20px;
        border: 1px solid #EBEEF3;
        background: #FFF;
        display: block;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        resize: none;
        outline: none;

        &::placeholder {
            color: var(--Grey, #AAB3BE);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }



        @media (max-width: 900px) {
            width: 296px;
            height: 88px;
        }

        &:focus {
            border-radius: 20px;
            border: 2px solid var(--Dark, #182F43);
            background: #FFF;
        }
    }
}

.form_name {
    display: flex;
    flex-direction: column;
    gap: 4px;

    input {
        width: 416px;
        padding: 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @media (max-width: 900px) {
            width: 300px;
        }
    }
}

.form_number {
    display: flex;
    flex-direction: column;
    gap: 4px;

    input {
        width: 416px;
        padding: 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @media (max-width: 900px) {
            width: 300px;
        }
    }
}

.form_checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form_accept {
    padding-top: 48px;
    display: flex;
    justify-content: center;
    gap: 10px;

    label {
        input {
            display: none;
        }

        span {
            height: 12px;
            width: 12px;
            border: 1px solid gray;
            display: inline-block;
            position: relative;
            background-color: #FFF;
            border-radius: 2px;
            padding: 3px;
            cursor: pointer;
        }

        [type=checkbox]:checked+span:before {
            content: '\2714';
            position: absolute;
            top: -3px;
            left: 0;
            font-size: 23px;
            color: rgb(0, 0, 0);
        }
    }

    a {
        margin-left: 5px;
        color: var(--Blue, #2D7CD8);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.form_checkbox_span {
    height: 12px;
    width: 12px;
    border: 1px solid gray;
    display: inline-block;
    position: relative;
    background-color: #FFF;
    border-radius: 2px;
    padding: 3px;
}

.form_submit {
    width: 400px;
    height: 64px;
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 40px;
    background: var(--Dark, #182F43);
    box-shadow: 0px 4px 30px 0px rgba(32, 78, 119, 0.20);
    cursor: pointer;
    border: none;

    @media (max-width: 900px) {
        width: 300px;
    }

    &:hover {
        background: #102231;
    }

    &:active {
        background: #0f2334;
    }
}



.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button_wrap {
    padding-top: 17.5px;
    display: flex;
    justify-content: center;
}