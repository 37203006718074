.container {
    display: flex;
    padding: 64px 0px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    align-self: center;
    width: 1200px;

    @media (max-width: 900px) {
        padding: 20px;
        gap: 32px;
        margin-top: 80px;
        flex-direction: column-reverse;
        width: 340px;
    }
}

.container_top {
    display: flex;
    flex-direction: row;
    gap: 48px;
    border-radius: 60px;
    background: #FFF;
    padding: 48px;

    @media (max-width: 900px) {
        flex-direction: column-reverse;
        padding: 0px;
        gap: 24px;
    }

    span {
        color: #6B6D7B;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @media (max-width: 900px) {
            font-size: 16px;
        }
    }

    h2 {
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        letter-spacing: -1.44px;
        background: var(--_, linear-gradient(0deg, #256CAC 8.49%, #234B6D 85.34%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 900px) {
            font-size: 32px;
            letter-spacing: -0.96px;
        }

        img {
            width: 68px;
            height: 68px;
            position: relative;
            top: 20px;

            @media (max-width: 900px) {
                width: 44px;
                height: 44px;
                top: 10px;
            }
        }
    }
}

.integration {
    position: relative;
    bottom: 55px;
}

.img_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 450px;
    border-radius: 40px;
    background: var(--_, linear-gradient(180deg, #ECF9FF 0%, rgba(255, 248, 241, 0.00) 100%));
    position: relative;
    top: 20px;

    @media (max-width: 900px) {
        width: auto;
        margin-bottom: 50px;
    }

    img {
        width: 610px;

        @media (max-width: 900px) {
            width: 300px;

        }
    }
}

.container_botton {
    display: flex;
    flex-direction: row;
    gap: 48px;
    background: #FFF;

    padding: 48px;

    h2 {
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        letter-spacing: -1.44px;
        background: var(--_, linear-gradient(0deg, #256CAC 8.49%, #234B6D 85.34%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    span {
        color: #6B6D7B;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
}