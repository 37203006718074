.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 900px) {
        width: 360px;
    }

}

.container_fixed {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 900px) {
        width: 360px;
    }
}