.supercharge {
    display: flex;
    width: 1200px;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    border-radius: 120px;
    background: var(--__, linear-gradient(204deg, #FFF 32.69%, #F0F9FF 80.77%));

    @media (max-width: 900px) {
        width: 360px;
        border-radius: 30px;
    }

}

.supercharge_title {
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.title {
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    padding-bottom: 5px;
    letter-spacing: -2.88px;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, #2B5D89 0%, #182F43 87.61%);
    background-clip: text;
    -webkit-background-clip: text;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 900px) {
        align-self: stretch;
        font-size: 48px;
        line-height: 100%;
        letter-spacing: -1.44px;
    }
}

.supercharge_sunImg {
    padding-top: 60px;
    width: 68px;
    height: 68px;
    transform: rotate(-23.052deg);

    @keyframes sun {
        0% {
            transform: rotate(-30deg);
        }

        50% {
            transform: translateY(60px) rotate(-50deg);
        }

        100% {
            transform: translateY(0px) rotate(-80deg);
        }
    }

    animation: 7s ease-in-out 0s infinite normal none running sun;

    @media (max-width: 900px) {
        display: none;
    }
}

.supercharge_likeImg {
    padding-top: 60px;
    display: flex;
    width: 84px;
    height: 84px;
    transform: rotate(-18.25deg);

    @media (max-width: 900px) {
        display: none;

    }

    @keyframes sun {
        0% {
            transform: rotate(-30deg);
        }

        50% {
            transform: translateY(60px) rotate(20deg);
        }

        100% {
            transform: translateY(0px) rotate(-30deg);
        }

    }

    animation: 7s ease-in-out 0s infinite normal none running sun;

}

.features {
    width: 600px;
    border-radius: 40px;
    box-shadow: 0px 6px 60px 0px rgba(22, 71, 101, 0.05);
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    flex: 1 0 0;

    @media (max-width: 900px) {
        width: 312px;
        flex-direction: column;
        padding: 24px;
    }

    h4 {
        color: #2D7CD8;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.tree {
    height: 382px;

    @media (max-width: 900px) {
        width: 360px;
    }
}

.main {
    width: 1100px;
    display: flex;
    flex: 1 0 0;

    @media (max-width: 900px) {
        width: 360px;
        flex-direction: column;
    }
}

.features_items {
    flex-direction: row;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    h5 {
        color: var(--Dark, #182F43);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    li {
        color: var(--Grey_2, #6B6D7B);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    p {
        color: var(--Grey_2, #6B6D7B);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    ul {
        padding-bottom: 50px;
    }

    @media (max-width: 900px) {
        h5 {
            font-size: 18px;
        }

        img {
            width: 40px;
            height: 40px;
        }

        li {
            font-size: 16px;
        }

        ul {
            padding-bottom: 0px;
            padding-left: 30px;
        }

        p {
            font-size: 16px;
        }
    }
}

.features_items_text {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h5 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    p {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.treeSection {
    width: 1100px;
    margin-top: 200px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    border-radius: 60px;
    background: #FFF;
    box-shadow: 0px 6px 60px 0px rgba(22, 71, 101, 0.10);

    @media (max-width: 900px) {
        width: 360px;
        flex-direction: column;
        border-radius: 30px;
        margin-top: 100px;
    }

    button {
        padding: 10px;
        border-radius: 40px;
        background: #E4F1FD;
        color: var(--Blue, #2D7CD8);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border: 0;
    }

    h2 {
        padding-top: 20px;
        margin: 0px;
        text-align: start;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 64px;
        letter-spacing: -1.92px;
        -webkit-text-fill-color: transparent;
        background: linear-gradient(180deg, #2B5D89 0%, #182F43 87.61%);
        background-clip: text;
        -webkit-background-clip: text;

        @media (max-width: 900px) {
            font-size: 48px;
            letter-spacing: -1.44px;

        }
    }

    p {
        color: var(--Grey_2, #6B6D7B);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
    }
}

.treeWrap {
    border-radius: 40px;
    background: var(--_, linear-gradient(180deg, #ECF9FF 0%, rgba(255, 248, 241, 0.00) 100%));
    margin: 48px;
    width: 382px;
    height: 382px;

    @media (max-width: 900px) {
        width: 360px;
        margin-top: 30px;
    }

}

.watermelon {
    width: 382px;
    height: 382px;
}

.contentWrap {
    flex-basis: 70%;

    @media (max-width: 900px) {
        padding-bottom: 5px;

        p {
            color: var(--Grey_2, #6B6D7B);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            padding: 8px;
        }

        h2 {
            text-align: start;
            padding: 8px;
        }
    }
}

.mapImg {
    height: 68px;
    position: relative;
    top: 10px;

    @media (max-width: 900px) {

        position: absolute;
        top: 3007px;
        right: 55px;
        width: 50px;
        height: 50px;
    }
}