.container {
    margin-top: 200px;
    display: flex;
    width: 944px;
    height: 1063px;
    padding: 48px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    border-radius: 40px;
    background: #F6F8FC;

    @media (max-width: 900px) {
        width: 328px;
        padding: 24px 16px;
        gap: 32px;
        margin-top: 80px;
        margin-bottom: 300px;
    }
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    align-self: stretch;
    border-radius: 30px;

    @media (max-width: 900px) {
        gap: 32px;
    }

    button {
        display: flex;
        width: 400px;
        height: 64px;
        padding: 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 40px;
        background: var(--Dark, #182F43);
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        box-shadow: 0px 4px 30px 0px rgba(32, 78, 119, 0.20);
        border: none;

        @media (max-width: 900px) {
            width: 296px;
        }

        &:hover {
            background: #102231;
        }

        &:active {
            background: #0f2334;
        }
    }
}

.form_merchant {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.form_merchant_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    @media (max-width: 900px) {
        gap: 8px;
    }

    p {
        color: #182F43;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;


        @media (max-width: 900px) {
            font-size: 16px;

        }
    }

    span {
        color: var(--Blue, #2D7CD8);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;

        @media (max-width: 900px) {
            font-size: 16px;
            margin: 0px;
        }
    }
}

.form_merchant_details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.form_merchant_names {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;

    @media (max-width: 900px) {
        flex-direction: column;
        align-items: stretch;
    }
}

.form_merchant_contacts {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    @media (max-width: 900px) {
        flex-direction: column;
        align-items: stretch;
    }
}

.form_merchant_first {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 50%;
}

.form_merchant_second {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 50%;
}

.form_span {
    color: var(--Dark, #182F43);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
}

.form_input {
    display: flex;
    height: 16px;
    padding: 16px 0px 16px 16px;
    align-items: center;
    align-self: stretch;
    border-radius: 20px;
    border: 1px solid #EBEEF3;

    background: #FFF;
}

.form_span_number {
    color: var(--Grey, #AAB3BE);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 18px */
}

.form_company {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.form_title {
    margin: 0px;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -1.92px;
    background: var(--_, linear-gradient(180deg, #2B5D89 0%, #182F43 87.61%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 900px) {
        font-size: 32px;
        line-height: 150%;
    }
}

.form_title_company {
    margin: 0px;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -1.92px;
    background: var(--_, linear-gradient(180deg, #2B5D89 0%, #182F43 87.61%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 900px) {
        font-size: 32px;
    }
}

.form_company_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.modal_form {

    input {
        align-items: stretch;
    }
}

.span_bold {
    color: #182F43;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.span_normal {
    color: #182F43;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.company_name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.form_company_inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}