.write {
    display: flex;
    width: 1200px;
    height: 619px;
    padding: 48px 44px 0px 44px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    border-radius: 60px;
    background: var(--_, linear-gradient(90deg, #FFF9EC 3.01%, #EBF8FF 98.58%));

    @media (max-width: 900px) {
        width: 328px;
        padding: 16px 16px 0px 16px;
        margin-top: 80px;
        gap: 16px;
        border-radius: 30px;
        height: auto;
    }

    h2 {
        text-align: center;
        margin: 0px;
        padding-bottom: 10px;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 100.5%;
        letter-spacing: -1.92px;
        background: var(--_, linear-gradient(180deg, #2B5D89 0%, #182F43 87.61%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 900px) {
            font-size: 48px;
            letter-spacing: -1.44px;
        }
    }

    img {
        position: relative;
        top: 10px;
        width: 68px;
        height: 68px;

        @media (max-width: 900px) {
            width: 44px;
            height: 44px;
            top: 5px;
        }
    }

    div {
        @media (max-width: 900px) {
            border-radius: 30px;
            position: relative;
            top: 0px;
        }
    }
}


.top_button {
    padding: 10px;
    border-radius: 40px;
    background: #E4F1FD;
    color: var(--Blue, #2D7CD8);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 0;

    @media (max-width: 900px) {
        margin-top: 10px;
    }
}

.task_text {
    padding: 0px;
    margin: 0px;
}

.task_1 {
    @keyframes sun {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: translateY(30px) rotate(20deg);
        }

        100% {
            transform: translateY(0px) rotate(0deg);
        }
    }

    animation: 7s ease-in-out 0s infinite normal none running sun;
}

.task_2 {
    @keyframes second {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: translateY(30px) rotate(-30deg);
        }

        100% {
            transform: translateY(0px) rotate(0deg);
        }
    }

    animation: 8s ease-in-out 0s infinite normal none running second;
}

.task_3 {
    @keyframes third {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: translateY(30px) rotate(-70deg);
        }

        100% {
            transform: translateY(0px) rotate(0deg);
        }
    }

    animation: 18s ease-in-out 0s infinite normal none running third;
}

.task_4 {
    @keyframes four {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: translateX(70px) rotate(20deg);
        }

        100% {
            transform: translateY(0px) rotate(0deg);
        }
    }

    animation: 8s ease-in-out 0s infinite normal none running four;
}

.task_5 {
    @keyframes five {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: translateX(20px) rotate(60deg);
        }

        100% {
            transform: translateY(0px) rotate(0deg);
        }
    }

    animation: 12s ease-in-out 0s infinite normal none running five;
}

.task_6 {
    @keyframes six {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: translatey(20px) rotate(-60deg);
        }

        100% {
            transform: translateY(0px) rotate(0deg);
        }
    }

    animation: 12s ease-in-out 0s infinite normal none running six;
}