.modal {
    display: flex;
    flex-direction: column;
    width: 420px;
    padding: 32px;
    border-radius: 40px;
    background: #F6F8FC;
    gap: 32px;
    margin-top: 40px;
    position: absolute;
    top: 100px;
    left: 700px;
    z-index: 15;

    @media (max-width: 900px) {
        margin-top: 100px;
        width: 339px;
        padding: 10px;
        left: 370px;
    }

    h3 {
        margin: 0;
        margin-top: 10px;
        margin-left: 10px;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.96px;
        background: var(--_, linear-gradient(180deg, #2B5D89 0%, #182F43 87.61%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    a {
        margin-top: 15px;
        margin-right: 10px;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        div {
            display: flex;
            flex-direction: column;

        }

        button {
            margin-top: 16px;
            display: flex;
            padding: 16px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 40px;
            background: var(--Dark, #182F43);
            box-shadow: 0px 4px 30px 0px rgba(32, 78, 119, 0.20);
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            border: none;

            &:active {
                background: #0F2334;
            }
        }



        p {
            margin: 4px;
            color: var(--Dark, #182F43);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            align-self: stretch;
        }

        input {
            display: flex;
            justify-self: stretch;
            height: 16px;
            padding: 16px 0px 16px 16px;
            border-radius: 20px;
            border: 1px solid #EEF0F4;
            background: #FFF;
        }

        span {
            color: var(--Grey, #AAB3BE);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.9px;
            align-self: center;
            padding: 24px;
        }
    }
}

.modal_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img {
        position: relative;
        bottom: 20px;
        left: 10px;
        cursor: pointer;

        @media (max-width: 900px) {
            position: static;
        }


    }
}

.fill {
    align-self: center;
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 40px;
    background: #FF6D6D;
    width: 147px;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.error {
    align-self: center;
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 40px;
    background: #FF6D6D;
    width: 147px;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.bg {
    top: -33px;
    left: -370px;
    height: 1000px;
    width: 2400px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);

    position: fixed;

}