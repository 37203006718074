.container {
    display: flex;
    width: 1200px;
    padding: 200px 100px 72px 100px;
    flex-direction: column;
    align-items: center;
    gap: 60px;

    @media (max-width: 900px) {
        margin-top: 80px;
        width: 328px;
        padding: 24px 16px;
        gap: 48px;
        align-self: stretch;
        border-radius: 30px;
        box-shadow: 0px 6px 60px 0px rgba(33, 33, 33, 0.04);
    }
}

.container_description {
    display: flex;
    flex-direction: row;
    gap: 25px;

    @media (max-width: 900px) {
        flex-direction: column-reverse;
        gap: 16px;
    }

}

.container_description_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    align-self: stretch;

    @media (max-width: 900px) {
        gap: 24px;
    }
}

.container_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        text-align: center;
    }



    h2 {
        margin: 0px;
        padding-bottom: 10px;
        font-family: Inter;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 100.5%;
        letter-spacing: -1.92px;
        background: var(--_, linear-gradient(180deg, #2B5D89 0%, #182F43 87.61%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 900px) {
            font-size: 48px;
            letter-spacing: -1.44px;
        }
    }
}

.top_button {
    width: 189px;
    padding: 10px;
    border-radius: 40px;
    background: #E4F1FD;
    color: var(--Blue, #2D7CD8);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 0;

    @media (max-width: 900px) {
        width: auto;
    }
}

.plain {
    border-radius: 500px;
    padding: 0px 24px 0px 25px;
    height: 400px;
    align-items: center;
    background: var(--_, linear-gradient(180deg, #ECF9FF 0%, rgba(255, 248, 241, 0.00) 100%));

    @media (max-width: 900px) {
        width: 268px;
        height: 268px;
        flex-shrink: 0;
    }

}

.container_description_item {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: flex-start;
    gap: 23px;
}

.container_description_li {
    h5 {
        padding: 0px;
        margin: 0px;
        color: var(--Dark, #182F43);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        @media (max-width: 900px) {
            font-size: 18px;
        }

    }

    p {
        color: var(--Grey_2, #6B6D7B);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @media (max-width: 900px) {
            font-size: 16px;
        }
    }
}

.container_description_number {
    border-radius: 50px;
    background: linear-gradient(0deg, #234B6D 8.49%, #256CAC 85.34%);
    width: 64px;
    height: 64px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #FFF;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 100.5%;
    letter-spacing: -0.84px;

    @media (max-width: 900px) {
        display: flex;
        width: 65px;
        height: 33px;
        padding: 14.625px 0px 14.375px 0px;
        justify-content: center;
        align-items: center;
    }
}