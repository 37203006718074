.container {
    display: flex;
    width: 480px;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 40px;
    background: #F6F8FC;
    position: absolute;
    z-index: 4;
    margin-top: 500px;

    @media (max-width: 900px) {
        width: 290px;
        margin-top: 10px;
    }

    h5 {
        margin: 0px;
        color: var(--Dark, #182F43);
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.96px;
    }

    p {
        color: var(--Dark, #182F43);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        align-self: stretch;
    }

    button {
        display: flex;
        height: 64px;
        padding: 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 40px;
        background: var(--Dark, #182F43);
        box-shadow: 0px 4px 30px 0px rgba(32, 78, 119, 0.20);
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border: none;

        &:hover {
            background: #14293C;
        }

        &:active {
            background: #0F2334;
        }
    }
}