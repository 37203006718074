.manage {
    display: flex;
    justify-content: center;
    width: 1200px;
    padding: 48px;
    align-items: center;
    border-radius: 60px;
    gap: 50px;

    @media (max-width: 900px) {
        width: 360px;
        flex-direction: column-reverse;
        padding: 0px;
        border-radius: 30px;
        gap: 0px;
        margin-top: 80px;
    }
}

.manage_contentWrap {
    width: 550px auto;

    @media (max-width: 900px) {
        width: 360px;
    }

    p {
        color: var(--Grey_2, #6B6D7B);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @media (max-width: 900px) {
            width: 320px;
            font-size: 16px;
            line-height: 150%;
            padding-left: 20px;
        }
    }
}

.manage_imgWrap {
    height: 500px auto;
    width: 500px auto;

    @media (max-width: 900px) {
        width: 360px;
        height: auto;
    }
}

.manage_img_mountain {
    height: 616px;
    border-radius: 200px;
    background: var(--_, linear-gradient(180deg, #ECF9FF 0%, rgba(255, 248, 241, 0.00) 100%));

    @media (max-width: 900px) {
        width: 360px;
        height: 360px;
        border-radius: 100px;
    }

}

.titleWrap {
    display: flex;
    flex-direction: row;

    h2 {
        margin: 0px;
        padding-bottom: 10px;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 100.5%;
        letter-spacing: -1.92px;
        background: var(--_, linear-gradient(180deg, #2B5D89 0%, #182F43 87.61%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 900px) {
            margin-left: 20px;
            font-size: 48px;
            letter-spacing: -1.44px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    p {
        color: var(--Grey_2, #6B6D7B);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @media (max-width: 900px) {
            font-size: 16px;
            line-height: 150%;
        }
    }
}

.top_button {
    margin-bottom: 32px;
    padding: 10px;
    border-radius: 40px;
    background: #E4F1FD;
    color: var(--Blue, #2D7CD8);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 0;

    @media (max-width: 900px) {
        margin-left: 20px;
    }
}

.botton_buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;

    @media (max-width: 900px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    p {
        border-radius: 4px;
        background: #E4F1FD;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        color: var(--Blue, #2D7CD8);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;

        @media (max-width: 900px) {
            text-align: center;
        }
    }
}

.manage_img_smile {
    height: 68px;
    position: relative;
    right: 200px;
    top: 60px;

    @media (max-width: 900px) {
        width: 44px;
        height: 44px;
        right: 45px;
        top: 55px;
    }
}