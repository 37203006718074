.header {
    width: 1152px;
    position: fixed;
    top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 172px;
    backdrop-filter: blur(8px);
    margin: 1rem auto;
    z-index: 2;
    border-radius: 40px;
    background: #fff;
    box-shadow: 0px 4px 60px 0px rgba(113, 122, 114, 0.15);

    img {
        margin-top: 5px;
    }

    @media (max-width: 900px) {
        display: none;
    }
}

.navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 48px;

    @media (max-width: 360px) {
        display: none;

    }
}

.SignIn {
    border: none;
    outline: none;
    background: #fff;
    color: var(--Dark, #182F43);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: var(--Dark, #182F43);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        transition: 0.4s;
    }

    &:active {
        color: var(--Dark, #182F43);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        transition: 0.4s;
    }
}

.links {
    text-decoration: none;
    color: #000000;
    color: var(--Dark, #182F43);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &:hover {
        color: var(--Dark, #182F43);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        transition: 0.4s;
    }

    &:active {
        color: var(--Dark, #182F43);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        transition: 0.4s;
    }
}