.address_current {
    color: #182F43;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.container {
    padding-bottom: 64px;
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    width: 1440px;
    height: 439px;
    background-color: #FFF;

    @media (max-width: 900px) {
        width: 328px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;
        padding-top: 80px;
    }

    span {
        color: var(--Grey, #AAB3BE);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-top: 10px;
    }
}

.container_address {
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 400px;

    @media (max-width: 900px) {
        width: 328px;
        gap: 24px;
    }
}

.address {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (max-width: 900px) {
        padding-left: 20px;
    }
}

.registration {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (max-width: 900px) {
        padding-left: 20px;
    }
}

.title {
    color: var(--Grey, #AAB3BE);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.container_top {
    display: flex;
    flex-direction: row;

    @media (max-width: 900px) {
        flex-direction: column;
        width: 328px;
    }

}

.links {
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 40px;

    @media (max-width: 900px) {
        width: 328px;
        padding-left: 20px;
        padding-top: 32px;
        gap: 8px;
        padding-bottom: 24px;

    }

    a {
        padding: 8px 0px;
        text-decoration: none;
        color: #000000;
        color: var(--Dark, #182F43);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.links_current {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.contacts_number {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.contacts {
    display: flex;
    flex-direction: column;
    gap: 48px;

    @media (max-width: 900px) {
        padding-left: 20px;
        gap: 12px;

    }
}

.contacts_email {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.container_botton {
    display: flex;
    flex-direction: row;
    gap: 150px;

    @media (max-width: 900px) {
        flex-direction: column;
        gap: 20px;
        padding-left: 20px;
    }

    a {
        padding: 10px;
        color: var(--Dark, #182F43);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: none;
    }
}

.container_botton_imgWrap {
    padding: 10px;
    width: 403px;

    @media (max-width: 900px) {
        padding: 0px;
        width: auto;
    }
}

.container_botton_links {
    display: flex;
    gap: 25px;

    @media (max-width: 900px) {
        flex-direction: column;
    }
}

.container_endLine {
    bottom: 50px;
    position: relative;
    padding-top: -30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    z-index: 2;
    width: 1010px;
    height: 1px;
    background: rgba(180, 183, 206, 0.50);

    @media (max-width: 900px) {
        width: auto;
        margin-left: 20px;
        margin-top: 30px;

    }

    span {
        padding-top: 24px;

        @media (max-width: 900px) {
            width: auto;
            padding-bottom: 20px;
        }
    }
}