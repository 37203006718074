.container {
    width: 1440px;
    height: 700px;
    background-image: url('../../../assets/Abstraction.svg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 900px) {
        display: flex;
        width: 344px;
        padding: 120px 8px 0px 8px;
        align-items: flex-start;
        height: auto;
    }

    h2 {
        margin-bottom: 30px;
        padding-top: 50px;
        padding-bottom: 10px;
        text-align: center;
        font-size: 96px;
        font-style: normal;
        font-weight: 700;
        line-height: 100.2%;
        letter-spacing: -2.88px;
        background: var(--_, linear-gradient(180deg, #2B5D89 0%, #182F43 87.61%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 900px) {
            text-align: center;
            font-size: 48px;
            letter-spacing: -1.44px;
            padding: 0;
            align-self: center;
            margin: 0px;
        }
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--Dark, #182F43);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
}

.buttons {
    margin-top: 72px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    button {
        cursor: pointer;

        @media (max-width: 900px) {}
    }

    @media (max-width: 900px) {
        width: 340px;
        align-items: start;
    }


}

.sky_buttons {
    display: flex;
    gap: 16px;
    justify-content: center;

    @media (max-width: 900px) {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        width: 340px;
        justify-content: flex-start
    }

    button {
        border: 1px solid #ECEFF3;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
        background: #418EE6;
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

}

.hover {
    &:hover {

        background: #fff;
        color: #182F43;
        border: 1px solid #ECEFF3;
        transition: 0.4s;
    }


}

.blue_buttons {
    display: flex;
    gap: 16px;
    justify-content: center;

    @media (max-width: 900px) {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        width: 340px;
        justify-content: flex-start
    }

    button {
        border: 1px solid #ECEFF3;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
        background: #2F70BB;
        color: #FFF;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.dark_button {
    display: flex;
    gap: 16px;
    justify-content: center;

    @media (max-width: 900px) {

        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        align-self: center;
    }

    button {
        border: 1px solid #ECEFF3;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
        background: #224460;
        color: #FFF;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

}