.container {
    width: 1200px;
    display: flex;
    padding: 64px 0px;
    flex-direction: column;
    align-items: center;
    gap: 48px;

    @media (max-width: 900px) {
        padding: 20px;
        gap: 32px;
        width: 320px;
    }
}

.title_wrap {
    display: flex;
    flex-direction: row;
}

.container_top {
    display: flex;
    flex-direction: row;
    gap: 48px;
    border-radius: 60px;
    background: #FFF;
    padding: 48px;

    @media (max-width: 900px) {
        gap: 32px;
        flex-direction: column;
        padding: 0px;
    }

    span {
        color: #6B6D7B;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @media (max-width: 900px) {
            font-size: 16px;
        }
    }

    h2 {
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        letter-spacing: -1.44px;
        background: var(--_, linear-gradient(0deg, #256CAC 8.49%, #234B6D 85.34%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 900px) {
            font-size: 32px;
            letter-spacing: -0.96px;
            align-self: center;
        }
    }
}

.integration {
    position: relative;
    bottom: 55px;
}

.img_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 450px;
    border-radius: 40px;
    background: var(--_, linear-gradient(180deg, #ECF9FF 0%, rgba(255, 248, 241, 0.00) 100%));

    @media (max-width: 900px) {
        width: 300px;
        align-self: center;
    }

    img {
        width: 540px;

        @media (max-width: 900px) {
            width: 300px;
            align-self: center;
        }
    }
}

.container_botton {
    display: flex;
    flex-direction: row;
    gap: 48px;
    background: #FFF;
    padding: 48px;

    @media (max-width: 900px) {
        gap: 32px;
        flex-direction: column-reverse;
        padding: 0px;
        align-items: center;
        align-self: center;
    }


    h2 {
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        letter-spacing: -1.44px;
        background: var(--_, linear-gradient(0deg, #256CAC 8.49%, #234B6D 85.34%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 900px) {
            font-size: 32px;
            letter-spacing: -0.96px;
            text-align: center;
        }
    }

    span {
        color: #6B6D7B;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
}

.boat {
    width: 68px;
    height: 68px;
    position: relative;
    top: 70px;
    right: 390px;

    @media (max-width: 900px) {
        top: 87px;
        right: 90px;
        width: 44px;
        height: 44px;

    }
}