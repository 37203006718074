.container_routes {
    width: 1440px;
    border-radius: 120px;
    background: var(--__, linear-gradient(204deg, #FFF 32.69%, #F0F9FF 80.77%));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
    margin-top: 100px;

    @media (max-width: 900px) {
        width: 360px;
        gap: 120px;
        margin: 0px;
        border-radius: 60px;
        padding-bottom: 10px;
    }


}

.container {
    background: no-repeat top/90% url('../../assets/Abstraction.svg');
    width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 900px) {
        width: 360px;
        background-size: contain;
    }

    h2 {
        margin-bottom: 30px;
        padding-top: 50px;
        padding-bottom: 10px;
        text-align: center;
        font-size: 96px;
        font-style: normal;
        font-weight: 700;
        line-height: 100.2%;
        letter-spacing: -2.88px;
        background: var(--_, linear-gradient(180deg, #2B5D89 0%, #182F43 87.61%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 900px) {
            font-size: 48px;
            letter-spacing: -1.44px;
            margin-top: 80px;
        }
    }

}



.buttons_block {
    padding: 16px;
    border-radius: 40px;
    background: #FFF;
    box-shadow: 0px 4px 30px 0px rgba(32, 78, 119, 0.20);
    display: flex;
    flex-direction: row;
    gap: 16px;
    position: sticky;
    top: 120px;

    @media (max-width: 900px) {
        top: 80px;
        padding: 8px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        width: 340px;
    }


    a {
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
        background: #182F43;
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: 1px solid #ECEFF3;
        text-decoration: none;

        @media (max-width: 900px) {
            font-size: 14px;
        }
    }

}

.active {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    background: #1071c6;
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 1px solid #ECEFF3;
    text-decoration: none;
}

.button {

    &:hover {
        border-radius: 30px;
        border: 1px solid #ECEFF3;
        background: #FFF;
        color: var(--Dark, #182F43);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        transition: 0.4s;
        position: relative;

        @media (max-width: 900px) {
            font-size: 14px;
        }
    }

    &:global.active {
        border-radius: 30px;
        border: 1px solid #ECEFF3;
        background: #FFF;
        color: var(--Dark, #182F43);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        transition: 0.4s;
        position: relative;

        @media (max-width: 900px) {
            font-size: 14px;
        }
    }


}

.frame {
    display: flex;
    width: 800px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.description {
    margin-top: 100px;
    height: 1050px;
    display: flex;
    padding: 64px 0px;
    flex-direction: column;
    align-items: center;
    gap: 200px;
    align-self: stretch;
    border-radius: 120px;
    background: var(--__, linear-gradient(204deg, #FFF 32.69%, #F0F9FF 80.77%));

    h3 {
        align-items: flex-start;
        margin: 0px;
        font-family: Inter;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: 100.5%;
        letter-spacing: -1.92px;
        background: var(--_, linear-gradient(0deg, #256CAC 8.49%, #234B6D 85.34%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    span {
        color: var(--Grey_2, #6B6D7B);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
}

.span_block {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 640px;
}

.terms {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    h5 {
        margin: 0px;
        color: var(--Dark, #182F43);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }

    span {
        color: var(--Grey_2, #6B6D7B);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    li {
        color: var(--Grey_2, #6B6D7B);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
}