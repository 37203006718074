.container {
    display: flex;
    position: fixed;
    top: 1rem;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    width: 360px;
    height: 56px;
    border-radius: 40px;
    background: #FFF;
    box-shadow: 0px 4px 30px 0px rgba(45, 124, 216, 0.15);
    z-index: 3;

    a {
        padding-top: 5px;
    }

    img {
        margin: 25px;
    }

    @media (min-width: 900px) {
        display: none;

    }
}

.modal {
    // bottom: 200px;
    margin-top: 500px;
    height: 100vh;
    width: 100vw;
    position: absolute;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);

}

.menu {
    position: absolute;
    display: flex;
    width: 328px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 30px 30px 0px 0px;
    background: #FFF;
    bottom: 0;

    img {
        margin: 5px;
    }

    div {
        width: 328px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    a {
        display: flex;
        height: 32px;
        padding: 16px;
        text-decoration: none;
        align-items: center;
        align-self: stretch;
        border-radius: 20px;
        color: var(--Dark, #182F43);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;

        &:active {
            border-radius: 20px;
            background: #E5F5F8;
        }
    }

    button {
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        align-self: center;
        border-radius: 40px;
        background: var(--Dark, #182F43);
        box-shadow: 0px 4px 30px 0px rgba(32, 78, 119, 0.20);
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border: none;
        width: 340px;

        &:active {
            background: #0F2334;
        }
    }
}