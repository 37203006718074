.container {
    display: flex;
    width: 1200px;
    padding: 48px;
    align-items: center;
    gap: 48px;

    @media (max-width: 900px) {
        width: 328px;
        display: flex;
        padding: 24px 16px;
        align-items: center;
        gap: 48px;
        align-self: stretch;
        flex-direction: column;
        margin-top: 80px;
    }
}

.container_title {
    width: 690px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    @media (max-width: 900px) {
        width: 328px;
        gap: 16px;
    }

    h2 {
        margin: 0px;
        padding-bottom: 10px;
        padding-right: 6px;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 100.5%;
        letter-spacing: -1.92px;
        background: var(--_, linear-gradient(180deg, #2B5D89 0%, #182F43 87.61%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 900px) {
            font-size: 48px;
            letter-spacing: -1.44px;
        }
    }
}

.container_animated {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 590px;

    @media (max-width: 900px) {
        width: 360px;

        img {
            width: 40px;
            height: 40px;
        }
    }

    img {
        position: relative;
        top: 5px;
        left: 10px;
        width: 52px;
        height: 52px;
        flex-shrink: 0;

        @media (max-width: 900px) {
            width: 40px;
            height: 40px;
        }
    }
}

.animated_intern {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 100.5%;
    letter-spacing: -1.44px;
    color: #B6B8CF;
    opacity: 0.4;

    @media (max-width: 900px) {
        font-size: 32px;
        letter-spacing: -0.96px;
    }
}

.animated_june {
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 100.5%;
    letter-spacing: -1.44px;
    color: #B6B8CF;
    opacity: 0.6;

    @media (max-width: 900px) {
        font-size: 32px;
        letter-spacing: -0.96px;
    }
}

.animated_medium {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 100.5%;
    letter-spacing: -1.44px;
    color: #B6B8CF;
    opacity: 0.8;

    @media (max-width: 900px) {
        font-size: 32px;
        letter-spacing: -0.96px;
    }
}

.animated_general {
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 100.5%;
    letter-spacing: -1.92px;
    background: var(--Orange, linear-gradient(90deg, #FFC087 0%, #FF8616 65.53%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 900px) {
        font-size: 36px;
        letter-spacing: -1.08px;
        padding: 0px;
    }

}

.top_button {
    margin-bottom: 32px;
    padding: 10px;
    border-radius: 40px;
    background: #E4F1FD;
    color: var(--Blue, #2D7CD8);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 0;

    @media (max-width: 900px) {
        align-self: center;
        margin-bottom: 0px;
    }
}

.botton_buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;

    p {
        border-radius: 4px;
        background: #E4F1FD;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        color: var(--Blue, #2D7CD8);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;

        @media (max-width: 900px) {
            align-self: center;
            margin-bottom: 0px;
            text-align: center;
            ;
        }
    }
}