.main {
    margin: 48px;
    padding-top: 200px;
    display: flex;

    @media (max-width: 900px) {
        width: 328px;
        padding: 24px 16px;
        flex-direction: column;
    }
}

.top_button {
    padding: 10px;
    border-radius: 40px;
    background: #E4F1FD;
    color: var(--Blue, #2D7CD8);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: 0;

    @media (max-width: 900px) {
        align-self: center;
    }
}

.title_wrap {
    display: flex;
    flex-direction: row;
}

.main_container {
    width: 704px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    @media (max-width: 900px) {
        width: 328px;
    }

    img {
        width: 68px;
        height: 68px;

        @media (max-width: 900px) {
            width: 44px;
            height: 44px;
            position: absolute;
            right: 1px;
        }
    }

    h2 {
        margin: 0px;
        padding-bottom: 10px;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 100.5%;
        letter-spacing: -1.92px;
        background: var(--_, linear-gradient(180deg, #2B5D89 0%, #182F43 87.61%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 900px) {
            letter-spacing: -1.44px;
            font-size: 48px;
        }

    }
}

.happy_shipWrap {
    border-radius: 40px;
    background: var(--_, linear-gradient(180deg, #ECF9FF 0%, rgba(255, 248, 241, 0.00) 100%));

    @media (max-width: 900px) {
        margin-top: 20px;
    }

    img {
        width: 418px;
        height: 418px;

        @media (max-width: 900px) {
            width: 329px;
            height: 329px;
        }
    }
}

.main_options {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;

    @media (max-width: 900px) {
        justify-content: center;
    }

    a {
        padding: 16px;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        background: #FFE4CD;
        color: var(--Dark, #182F43);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;

        &:hover {
            border-radius: 20px;
            background: #FFDABA;
            transition: 0.3s;
        }

        &:active {
            border-radius: 20px;
            background: #FFD3AD;
            transition: 0.2s;
        }
    }


}