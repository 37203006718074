.container {
    width: 1440px;
    height: 700px;
    background-image: url('../../../assets/Abstraction.svg');
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;

    @media (max-width: 900px) {
        width: 360px;
        background-size: contain;
    }
}



.account {
    margin-top: 200px;
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    @media (max-width: 900px) {
        padding: 0px 16px;
        gap: 32px;
        align-self: stretch;
        width: auto;
    }

    h3 {
        margin: 0px;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: 100.5%;
        letter-spacing: -1.92px;
        background: var(--_, linear-gradient(0deg, #256CAC 8.49%, #234B6D 85.34%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    p {
        color: var(--Grey_2, #6B6D7B);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
}

.container_title {
    margin-bottom: 30px;
    padding-top: 50px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 100.2%;
    letter-spacing: -2.88px;
    background: var(--_, linear-gradient(180deg, #2B5D89 0%, #182F43 87.61%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 900px) {
        font-size: 48px;
        letter-spacing: -1.44px;
        padding-top: 80px;
    }
}



.buttons_block {
    padding: 16px;
    border-radius: 40px;
    background: #FFF;
    box-shadow: 0px 4px 30px 0px rgba(32, 78, 119, 0.20);
    display: flex;
    flex-direction: row;
    gap: 16px;
    position: sticky;
    top: 120px;
    z-index: 1;

    @media (max-width: 900px) {
        padding: 10px;
        width: 340px;
        height: auto;
        gap: 5px;
        flex-direction: row;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        top: 80px;
    }

    button {
        border: 1px solid #ECEFF3;
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
        background: #182F43;
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        display: inline-block;

        @media (max-width: 900px) {
            font-size: 14px;
        }
    }

}

.button {
    &:hover {
        border-radius: 30px;
        border: 1px solid #ECEFF3;
        background: #FFF;
        color: var(--Dark, #182F43);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        transition: 0.4s;
        position: relative;

        @media (max-width: 900px) {
            font-size: 14px;
        }
    }
}

.description {
    margin-top: 80px;
    padding-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 120px;
    background: var(--__, linear-gradient(204deg, #FFF 32.69%, #F0F9FF 80.77%));

    @media (max-width: 900px) {
        padding: 48px 16px;
        border-radius: 40px;
    }

    h3 {
        position: relative;
        right: 270px;
        margin: 0px;
        padding-bottom: 32px;
        font-family: Inter;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: 100.5%;
        letter-spacing: -1.92px;
        background: var(--_, linear-gradient(0deg, #256CAC 8.49%, #234B6D 85.34%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: 900px) {
            font-size: 48px;
            letter-spacing: -1.44px;
            right: inherit;
        }
    }

    span {
        width: 800px;
        color: var(--Grey_2, #6B6D7B);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        word-wrap: break-word;

        @media (max-width: 900px) {
            width: 340px;
            height: auto;
            font-size: 16px;
        }

    }
}

.span_block {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 700px;

    @media (max-width: 900px) {
        height: auto;

    }
}