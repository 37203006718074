.cookies {
    display: inline-flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    position: fixed;
    bottom: 1rem;
    border-radius: 30px;
    background: var(--Backgrounds-and-Strokes-Background, #FFF);
    box-shadow: 0px 4px 30px 0px rgba(32, 78, 119, 0.20);
    z-index: 3;

    @media (max-width: 1000px) {
        flex-direction: column;
        gap: 16px;
        padding: 16px;
    }

    p {
        color: var(--Dark, #182F43);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    a {
        color: var(--Blue, #2D7CD8);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    button {
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        background: var(--Dark, #182F43);
        box-shadow: 0px 4px 30px 0px rgba(32, 78, 119, 0.20);
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;

        &:hover {
            background: #14293C;
        }

        &:active {
            background: #0F2334;

        }
    }


}

.dNone {
    opacity: 1;
}

.o1 {

    display: none;
}