.container {
    width: 800px;
    display: flex;
    flex-direction: column;
    padding-top: 50px;

    @media (max-width: 900px) {
        width: 328px;
        padding: 48px 16px;

    }

    h3 {
        margin: 0px;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: 100.5%;
        letter-spacing: -1.92px;
        background: var(--_, linear-gradient(0deg, #256CAC 8.49%, #234B6D 85.34%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-bottom: 20px;

        @media (max-width: 900px) {
            font-size: 48px;
            letter-spacing: -1.44px;

        }
    }

    h5 {
        padding-top: 20px;
        margin: 0px;
        color: var(--Dark, #182F43);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;

        @media (max-width: 900px) {
            font-size: 18px;
            line-height: 150%;

        }

    }

    p {
        color: var(--Grey_2, #6B6D7B);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @media (max-width: 900px) {
            font-size: 16px;

        }
    }

    span {
        color: var(--Grey_2, #6B6D7B);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }

    address {
        color: var(--Blue, #2D7CD8);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        display: inline-block;
    }
}