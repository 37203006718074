.main {
  background-image: url("../../../assets/Abstraction.svg");
  background-size: 1400px 900px;
  background-repeat: no-repeat;
  display: flex;
  width: 1200px;
  height: 600px;
  padding: 160px 0px 32px 0px;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    width: 360px;
    height: auto;
    background-size: 360px;
    padding: 120px 0px 32px 0px;
  }

  h3 {
    color: var(--Dark, #182f43);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 52px;

    @media (max-width: 900px) {
      font-size: 16px;
    }
  }
}

.title_wrap {
  height: 192px;
}

.icons {
  width: 40px;
  height: 40px;

  @media (max-width: 900px) {
    width: 24px;
    height: 24px;
  }
}

.pencil {
  width: 68px;
  height: 68px;
  position: relative;
  bottom: 70px;
  left: 819px;

  @media (max-width: 900px) {
    width: 43px;
    height: 43px;
    bottom: 45px;
    left: 290px;
  }
}

.vector {
  width: 296.978px;
  height: 13.08px;
  stroke: #182f43;
  stroke-width: 5px;
  position: relative;
  bottom: 73.7px;
  left: 510px;

  @media (max-width: 900px) {
    width: 180px;
    height: 8px;
    left: 100px;
    bottom: 47px;
  }
}

.title {
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 96px;
  padding-bottom: 5px;
  letter-spacing: -2.88px;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, #2b5d89 0%, #182f43 87.61%);
  background-clip: text;
  -webkit-background-clip: text;

  @media (max-width: 900px) {
    font-size: 48px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.44px;
  }
}

.button_blok {
  padding-bottom: 110px;
  display: flex;
  height: 64px;

  justify-content: center;
  align-items: center;
  gap: 10px;

  a {
    text-decoration: none;
  }

  @media (max-width: 900px) {
    padding-bottom: 52px;
    padding-top: 10px;
  }
}

.items_blok {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 190px;

  @media (max-width: 900px) {
    gap: 10px;
  }
}

.dark {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  color: #ffffff;
  border-radius: 40px;
  background: #182f43;
  height: 64px;
  padding: 32px;
  box-shadow: 0px 4px 30px 0px rgba(32, 78, 119, 0.2);
  cursor: pointer;

  &:hover {
    background: #102231;
  }

  &:active {
    background: #0f2334;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    font-weight: 700;
    padding: 16px;
  }
}

.items {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  p {
    color: #aab3be;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0px;

    @media (max-width: 900px) {
      color: var(--Grey_2, #6b6d7b);
      font-size: 14px;
    }
  }
}